//React
import React from 'react'

//Gatsby
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import FeatureQuad from '../../components/marketing/featureQuad'

const useCaseData = [
  {
    title: 'SMS to start',
    description:
      'Users receive a text message with a link to a short health symptom screener - no apps to download or install',
    image: 'featureOnboarding',
    iconShow: true,
    iconColor: 'purple',
    lineClass: 'pink-4',
  },
  {
    title: 'Assess health status',
    description:
      'Picohealth displays a questionnaire for users to certify their wellness, with questions fully customizable by you, and answers encrypted',
    image: 'featureScreening',
    iconShow: true,
    iconColor: 'blue',
    lineClass: 'blue-5',
  },
  {
    title: 'Drive action',
    description:
      'Drive action based on user responses - logging employee responses for audit, approving the visit/shift start, asking for more info, and more',
    image: 'featureScreeningNextsteps',
    iconShow: true,
    iconColor: 'sky',
    lineClass: 'green-4',
  },
]

const featureData = {
  title: 'Safely reopen your business',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'sky-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Mitigate risk',
          colbgcolor: 'blue-1',
          iconname: 'shield',
          description:
            'Reduce legal risk by implementing health self-certification, and produce a corresponding audit trail',
        },
        {
          title: 'Drive confidence',
          colbgcolor: 'sky-1',
          iconname: 'analytics',
          description:
            "Let your customers know you're safe for business by asking the right health questions",
        },
        {
          title: 'Reduce contact',
          colbgcolor: 'green-1',
          iconname: 'touchapp',
          description:
            'Use mobile devices to reduce contact and move away from in-person health interviews or paper-based forms',
        },
      ],
    },
  ],
}

const About = (props) => (
  <Layout>
    <Helmet
      title="Picohealth - How it works - Automated Mobile Health Assessment"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated health assessment platform helps screen your employees and customers for wellness so you can reopen your business',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    {/* <NavSubAbout /> */}
    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium dkgreen-1">
            Reduce risk as you re-open
          </h1>
          <p class="display-4">
            With automated mobile health self-certifications, Picohealth helps
            you reduce legal uncertainty and inspire customer confidence as you
            re-open
          </p>
        </div>
      </div>
    </div>
    {/* 
    <DividerMid
      class="transparent-1"
      bottomclass="blue-1"
      flip={true}
      lineclass="sky-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="yellow-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <div class="gradient-callout-cousteau">
      <div class="container container-page my-0 py-4">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1">How it works</h2>
            <p class="display-4 white-1 line-height-4">
              Employees and customers can certify their wellness before arrival
              with a few questions auto-delivered via text message
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* <DividerMid class="green-1" line={true} /> */}

    <AlternatingCallout featureData={useCaseData} />

    <FeatureQuad featureData={featureData} />

    {/* <BenefitsBoomerang
      bgcolor="seafoam-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Reach Gen Z + Millennials",
          body:
            "Overcome short attention spans with short, fast videos and the latest video effects",
        },
        {
          title: "Sharpen your social strategy",
          body:
            "Activate audiences on TikTok, Snapchat, and Instagram beyond just paid ads",
        },
        {
          title: "Rebuild & rebound",
          body:
            "Energize your community to get through crisis periods, and rebound using cause-based marketing",
        },
      ]}
    /> */}
  </Layout>
)

export default About

// export const pageQuery = graphql`
//   query {
//     jumbotron: file(relativePath: { eq: "poly/cool.png" }) {
//       childImageSharp {
//         fluid(quality: 90, maxWidth: 1800, maxHeight: 1000, cropFocus: CENTER) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     workflowChatbotInitial: file(
//       relativePath: { eq: "workflow/chatbot-initial.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 300, cropFocus: NORTH) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     workflowPhotoUpload: file(
//       relativePath: { eq: "workflow/chatbot-photoupload.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 420, cropFocus: NORTHWEST) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     workflowJournalPrompt: file(
//       relativePath: { eq: "workflow/chatbot-journalprompt.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 330, cropFocus: NORTH) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     workflowVideoLanding: file(
//       relativePath: { eq: "workflow/video-landing.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 330, cropFocus: NORTH) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     workflowVideoComposite: file(
//       relativePath: { eq: "workflow/video-composite.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 300, height: 360, cropFocus: NORTH) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     workflowVideoComposite1: file(
//       relativePath: { eq: "workflow/video-composite1.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 420, cropFocus: NORTHWEST) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     workflowVideoComposite2: file(
//       relativePath: { eq: "workflow/video-composite2.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 240, height: 420, cropFocus: NORTHWEST) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     arrowDown1: file(relativePath: { eq: "workflow/arrow-down-1.png" }) {
//       childImageSharp {
//         fixed(height: 80) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     arrowDown2: file(relativePath: { eq: "workflow/arrow-down-2.png" }) {
//       childImageSharp {
//         fixed(height: 80) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     arrowDown3: file(relativePath: { eq: "workflow/arrow-down-3.png" }) {
//       childImageSharp {
//         fixed(height: 80) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
